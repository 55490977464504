/* eslint-disable */
export default {
  "dirty": false,
  "raw": "v1.13.15-22-ge7e7da54",
  "hash": "ge7e7da54",
  "distance": 22,
  "tag": "v1.13.15",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.13.15",
    "major": 1,
    "minor": 13,
    "patch": 15,
    "prerelease": [],
    "build": [],
    "version": "1.13.15"
  },
  "suffix": "22-ge7e7da54",
  "semverString": "1.13.15+22.ge7e7da54"
};
